import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { borderRadiuses, colors, containers, fontSizes, gaps, fontFamilies } from './variables';
import { mq } from './stylehelpers';

/**
 * Helper, der einen String an "|" splitten um Farben und Themes zu trennen
 */
const getThemeAndColor = color => color.match(/(.*)\|(.*)/) || [null, null, color];

/**
 * Erweitert Elemente um die Möglichkeit, die Hintergrundfarbe anhand der definierten `colors` zu steuern.
 *
 * @param {number} defaultColor Optional: Die Standard-Farbe (Theme Auswahl über [theme]|color)
 *
 * @example ${bgColorable('blue100')};
 * @example ${bgColorable('sbf|primary')};
 */
export const bgColorable = (defaultColor = 'transparent') => ({ bgColor }) => {
    const [, theme, color] = !bgColor ? getThemeAndColor(defaultColor) : getThemeAndColor(bgColor);
    return `background-color: ${theme ? colors[theme][color] : colors[color]}`;
};
bgColorable.propType = PropTypes.string;

/**
 * Erweitert Elemente um die Möglichkeit, die Flex-Direktion zu steuern
 *
 * @param {number} defaultDirection Optional: Die Standard-Direktion
 *
 * @example ${flexDirectionable('row')};
 */
export const flexDirectionable = (defaultDirection = 'initial') => ({ direction }) =>
    `flex-direction: ${direction || defaultDirection}`;
flexDirectionable.propType = PropTypes.oneOf([
    'row',
    'row-reverse',
    'column',
    'column-reverse',
    'initial',
    'inherit',
]);

/**
 * Erweitert Elemente um die Möglichkeit, darstellung zu steuern (display: block/inline/...).
 *
 * @param {number} defaultDisplay Optional: Die Standard-Darstellung
 *
 * @example ${displayable('block')};
 */
export const displayable = (defaultDisplay = 'inherit') => ({ display }) =>
    `display: ${display || defaultDisplay}`;
displayable.propType = PropTypes.oneOf([
    'block',
    'contents',
    'flex',
    'grid',
    'inherit',
    'initial',
    'inline-block',
    'inline-flex',
    'inline-grid',
    'inline-table',
    'inline',
    'list-item',
    'none',
    'run-in',
    'table-caption',
    'table-cell',
    'table-column-group',
    'table-column',
    'table-footer-group',
    'table-header-group',
    'table-row-group',
    'table-row',
    'table',
]);

/**
 * Erweitert Elemente um die Möglichkeit, die Schriftgröße anhand der definierten `fontSizes` zu steuern.
 *
 * @param {number} defaultSize Optional: Die Standard-Größe
 *
 * @example ${fontSizable('m')};
 */
export const fontSizable = (defaultSize = 'm') => ({ fontSize }) =>
    `${fontSizes[fontSize] ? fontSizes[fontSize] : fontSizes[defaultSize]}`;
fontSizable.propType = PropTypes.oneOf(Object.keys(fontSizes));

/**
 * Erweitert Elemente um die Möglichkeit, die Abstände nach unten
 * über global einheitliche Werte zu steuern. Auch mit viewportspezifischen
 * Einstellungen möglich.
 *
 * Das Schlüsselwort `auto` verhindert die Angabe eines Abstands.
 *
 * @param {string} defaultGap Optional: der Standard-Abstand
 *
 * @example ${gapable('l')};
 * @example ${gapable({small: 'xl', medium: 'l', ...}};
 */
export const gapable = (defaultGap = 'none') => ({ gap }) => {
    if (gap === 'auto' || (!gap && defaultGap === 'auto')) {
        return null;
    }

    if ((gap && typeof gap === 'object') || (typeof defaultGap === 'object' && !gap)) {
        const gapObject = gap || defaultGap;
        let mqedGaps = '';
        Object.keys(gapObject).forEach(key => {
            const mqFunction = mq[key];

            if (key === 'small') {
                mqedGaps = css`
                    ${gaps[gapObject[key]]};
                `;
            } else if (mqFunction && gapObject[key]) {
                const query = css`
                    ${mqFunction`${gaps[gapObject[key]]}`};
                `;
                mqedGaps = css`${mqedGaps}${query}`;
            }
        });
        return mqedGaps;
    }

    // Gaps ohne mq
    return `${gap in gaps ? gaps[gap] : gaps[defaultGap]}`;
};
gapable.propType = (props, propName, componentName) => {
    const { [propName]: value } = props;
    if (!(value in gaps) && value !== 'auto' && typeof value !== 'object') {
        return new Error(
            `Invalid prop "${propName}" ("${value}") supplied to "${componentName}". Validation failed.`
        );
    }
    return null;
};

/**
 * Erweitert Elemente um die Möglichkeit, die Ausrichtung des Inhalts zu steuern.
 *
 * @param {number} defaultAlign Optional: Die Standard-Einstellung
 *
 * @example ${itemsAlignable('flex-end')};
 */
export const itemsAlignable = (defaultAlign = 'inherit') => ({ alignItems }) =>
    `align-items: ${alignItems || defaultAlign}`;
itemsAlignable.propType = PropTypes.oneOf([
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'baseline',
    'start',
    'end',
    'initial',
    'inherit',
]);

/**
 * Erweitert Elemente um die Möglichkeit, die Ausrichtung des Inhalts zu steuern.
 *
 * @param {number} defaultJustify Optional: Die Standard-Einstellung
 *
 * @example ${justifyable('space-between')};
 */
export const justifyable = (defaultJustify = 'inherit') => ({ justify }) =>
    `justify-content: ${justify || defaultJustify}`;
justifyable.propType = PropTypes.oneOf([
    'center',
    'flex-end',
    'flex-start',
    'inherit',
    'initial',
    'space-around',
    'space-between',
]);

/**
 * Erweitert Elemente um die Möglichkeit, den Box-Shadow über globale Werte zu steuern.
 *
 * @param {number} defaultLayer Optional: Die Standard-Ebene/Größe des Schattens
 *
 * @example ${layerable(1)};
 */
export const layerable = (defaultLayer = 0) => ({ layer }) =>
    (l => `box-shadow: ${l}px ${l}px ${l * 10}px rgba(0, 0, 0, ${l / 20 + 0.2})`)(
        typeof layer === 'number' ? layer : defaultLayer
    );
layerable.propType = PropTypes.oneOf([0, 1, 2, 3, 4, 5]);

/**
 * Erweitert Elemente um die Möglichkeit, die Ecken über globale Werte abzurunden.
 *
 * @param {number} defaultRadius Optional: Die Standard-Einstellung
 *
 * @example ${roundable('m')};
 */
export const roundable = (defaultRadius = 'none') => ({ radius }) =>
    `border-radius: ${borderRadiuses[radius] || borderRadiuses[defaultRadius]}`;
roundable.propType = PropTypes.oneOf(Object.keys(borderRadiuses));

/**
 * Erweitert Elemente um die Möglichkeit, die Ausrichtung von sich selbst zu steuern.
 *
 * @param {number} defaultAlign Optional: Die Standard-Einstellung
 *
 * @example ${selfAlignable('flex-end')};
 */
export const selfAlignable = (defaultAlign = 'inherit') => ({ alignSelf }) =>
    `align-self: ${alignSelf || defaultAlign}`;
selfAlignable.propType = PropTypes.oneOf([
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'baseline',
    'initial',
    'inherit',
]);

/**
 * Erweitert Elemente um die Möglichkeit, die Größe über global
 * einheitliche Werte zu steuern.
 *
 * @param {string} defaultSize Optional: die Standard-Größe
 *
 * @example ${sizable('l')};
 */
export const sizable = (defaultSize = 'auto') => ({ size }) =>
    `max-width: ${containers[size] ? containers[size] : defaultSize}`;
sizable.propType = PropTypes.oneOf(Object.keys(containers));

/**
 * Erweitert Elemente um die Möglichkeit, die Text-Ausrichtung zu steuern.
 *
 * @param {number} defaultAlign Optional: Die Standard-Ausrichtung
 *
 * @example ${textAlignable('center')};
 */
export const textAlignable = (defaultAlign = 'inherit') => ({ textAlign }) =>
    `text-align: ${textAlign || defaultAlign}`;
textAlignable.propType = PropTypes.oneOf([
    'center',
    'inherit',
    'initial',
    'justify',
    'left',
    'right',
]);

/**
 * Erweitert Elemente um die Möglichkeit, die Schriftfarbe anhand der definierten `colors` zu steuern.
 *
 * @param {number} defaultColor Optional: Die Standard-Farbe (Theme Auswahl über [theme]|color)
 *
 * @example ${textColorable('blue100')};
 * @example ${textColorable('sbf|primary')};
 */
export const textColorable = (defaultColor = 'black') => ({ textColor }) => {
    const [, theme, color] = !textColor
        ? getThemeAndColor(defaultColor)
        : getThemeAndColor(textColor);
    return `color: ${theme ? colors[theme][color] : colors[color]}`;
};
textColorable.propType = PropTypes.string;

/**
 * Erweitert Elemente mit der Möglichkeit, die font family zu steuern.
 *
 * @param {string} defaultFont Optional: Die Standard-FontFamily
 *
 * @example ${FontFamily('default')};
 */
export const fontChangeable = (defaultFont = `default`) => ({ font }) =>
    `font-family: ${fontFamilies[font] ? fontFamilies[font] : fontFamilies[defaultFont]}`;
fontChangeable.propType = PropTypes.oneOf(Object.keys(fontFamilies));
