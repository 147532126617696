import { createGlobalStyle } from 'styled-components';

import MinionProRegularEot from '../../../assets/fonts/MinionPro-Regular.eot';
import MinionProRegularWoff from '../../../assets/fonts/MinionPro-Regular.woff';
import MinionProRegularTtf from '../../../assets/fonts/MinionPro-Regular.ttf';

import { primaryColor, buttonTextColor } from 'helpers/themehelpers';
import { globalFramePadding, mq } from 'helpers/stylehelpers';

const GlobalStyles = createGlobalStyle`

    @font-face {
        font-family: 'MinionPro';
        src: url(${`${MinionProRegularEot}`});
        src: url(${`${MinionProRegularEot}#iefix`}) format('embedded-opentype'),
             url(${`${MinionProRegularWoff}`}) format('woff'),
             url(${`${MinionProRegularTtf}`}) format('truetype');
        font-style: normal;
        font-weight: normal;
        text-rendering: optimizeLegibility;
    }

    h1, h2, h3, h4 {
      color: #1b69b1 !important;
      font-family: 'MinionPro', 'Times New Roman', serif;
    }
`;

export default GlobalStyles;
