import theme from 'styled-theming';
import { darken } from 'polished';
import { colors } from './variables';

/**
 * Theming-Helper, der Name ist Programm
 * Die nachfolgenden Methoden müssen in den Komponenten, die gethemed werden sollen
 * importiert und aufgerufen werden:
 *
 * @example background-color: ${primaryColor};
 * */

export const primaryColor = theme('main', {
    sbf: colors.sbf.primary,
    vielLeicht: colors.vielLeicht.primary,
    meinBayern: colors.meinBayern.primary,
});

export const secondaryColor = theme('main', {
    sbf: colors.sbf.secondary,
    vielLeicht: colors.vielLeicht.secondary,
    meinBayern: colors.meinBayern.secondary,
});

export const primaryColorDarkened = theme('main', {
    sbf: darken(0.1, colors.sbf.primary),
    vielLeicht: darken(0.1, colors.vielLeicht.primary),
    meinBayern: darken(0.1, colors.meinBayern.primary),
});

export const secondaryColorDarkened = theme('main', {
    sbf: darken(0.1, colors.sbf.secondary),
    vielLeicht: darken(0.1, colors.vielLeicht.secondary),
    meinBayern: darken(0.1, colors.meinBayern.secondary),
});

export const headlineColor = theme('main', {
    sbf: colors.sbf.primary,
    vielLeicht: colors.vielLeicht.secondary,
    meinBayern: colors.meinBayern.secondary,
});

export const hoverAndActiveColor = theme('main', {
    sbf: colors.sbf.primary,
    vielLeicht: colors.vielLeicht.secondary,
    meinBayern: colors.meinBayern.primary,
});

export const buttonTextColor = theme('main', {
    sbf: colors.sbf.buttonTextColor,
    vielLeicht: colors.vielLeicht.buttonTextColor,
    meinBayern: colors.meinBayern.buttonTextColor,
});

export const buttonTextColorHover = theme('main', {
    sbf: colors.sbf.buttonTextColor,
    vielLeicht: colors.white,
    meinBayern: colors.white,
});

export const ghostButtonTextColor = theme('main', {
    sbf: colors.sbf.primary,
    vielLeicht: colors.vielLeicht.buttonTextColor,
    meinBayern: colors.meinBayern.buttonTextColor,
});

export const ghostButtonTextColorHover = theme('main', {
    sbf: colors.white,
    vielLeicht: colors.white,
    meinBayern: colors.white,
});

export const iconColor = theme('main', {
    sbf: colors.white,
    vielLeicht: colors.vielLeicht.secondary,
    meinBayern: colors.meinBayern.secondary,
});

export const textlinkColor = theme('main', {
    sbf: colors.sbf.primary,
    vielLeicht: colors.vielLeicht.secondary,
    meinBayern: colors.meinBayern.secondary,
});

export const textlinkColorDarkened = theme('main', {
    sbf: darken(0.2, colors.sbf.primary),
    vielLeicht: darken(0.2, colors.vielLeicht.secondary),
    meinBayern: darken(0.2, colors.meinBayern.secondary),
});
