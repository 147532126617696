import { css } from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from './variables';

/**
 * Helper um Code in Pseudo-Klassen für Hover etc. zu wrappen
 * @param  {String} args Zu wrappendes CSS
 * @return {String}      Gewrapptes CSS
 */
export const pseudoStateClasses = (...args) => css`
    &:hover,
    &:focus,
    &:active {
        ${css(...args)};
    }
`;

/**
 * Mixin, um wiederverwertbare MediaQueries zu erhalten
 * @param  {Object} mq Beliebige Anzahl von Zahlen
 * @return {String} In MQ gewrapptes CSS
 * @example
 *     font-size: 16px;
 *     ${mq.medium`font-size: 20px`};
 */
export const mq = {
    smallOnly: (...args) => css`
        @media (max-width: ${breakpoints.mediumDown}) {
            ${css(...args)};
        }
    `,
    medium: (...args) => css`
        @media (min-width: ${breakpoints.medium}) {
            ${css(...args)};
        }
    `,
    mediumDown: (...args) => css`
        @media (max-width: ${breakpoints.largeDown}) {
            ${css(...args)};
        }
    `,
    mediumOnly: (...args) => css`
        @media (min-width: ${breakpoints.medium}) and (max-width: ${breakpoints.largeDown}) {
            ${css(...args)};
        }
    `,
    large: (...args) => css`
        @media (min-width: ${breakpoints.large}) {
            ${css(...args)};
        }
    `,
    largeDown: (...args) => css`
        @media (max-width: ${breakpoints.xlargeDown}) {
            ${css(...args)};
        }
    `,
    largeOnly: (...args) => css`
        @media (min-width: ${breakpoints.large}) and (max-width: ${breakpoints.xlargeDown}) {
            ${css(...args)};
        }
    `,
    xlarge: (...args) => css`
        @media (min-width: ${breakpoints.xlarge}) {
            ${css(...args)};
        }
    `,
    xlargeDown: (...args) => css`
        @media (max-width: ${breakpoints.xxlargeDown}) {
            ${css(...args)};
        }
    `,
    xlargeOnly: (...args) => css`
        @media (min-width: ${breakpoints.xlarge}) and (max-width: ${breakpoints.xxlargeDown}) {
            ${css(...args)};
        }
    `,
    xxlarge: (...args) => css`
        @media (min-width: ${breakpoints.xxlarge}) {
            ${css(...args)};
        }
    `,
    xxlargeDown: (...args) => css`
        @media (max-width: ${breakpoints.xxxlargeDown}) {
            ${css(...args)};
        }
    `,
    xxlargeOnly: (...args) => css`
        @media (min-width: ${breakpoints.xxlarge}) and (max-width: ${breakpoints.xxxlargeDown}) {
            ${css(...args)};
        }
    `,
    xxxlarge: (...args) => css`
        @media (min-width: ${breakpoints.xxxlarge}) {
            ${css(...args)};
        }
    `,
};

/**
 * Exportiert globales Padding für Elemente die auf den "Seitenrand" laufen
 * z.B. Footer, Header, Stage etc.
 */
export const globalFramePadding = () => css`
    padding-left: ${rem(15)};
    padding-right: ${rem(15)};

    ${mq.large`
        padding-left: ${rem(20)};
        padding-right: ${rem(20)};
    `};

    ${mq.xxlarge`
        padding-left: ${rem(45)};
        padding-right: ${rem(45)};
    `};

    ${mq.xxxlarge`
        padding-left: ${rem(60)};
        padding-right: ${rem(60)};
    `};
`;

/**
 * Globaler Box-Shadow
 * @param  {String} [size='standard/large'] Größe des globalen Box Shadows
 * @return {String} Box Shadow CSS
 * @example
 *     ${boxShadow('large')}
 */
export const boxShadow = (size = 'standard') => css`
    box-shadow: 0px 0px ${size === 'large' ? rem(10) : rem(5)} rgba(0, 0, 0, 0.3);
`;
