import { createGlobalStyle } from 'styled-components';
import { normalize, rem } from 'polished';

import { mq } from 'helpers/stylehelpers';
import { fontSizes, fontFamilies, breakpoints, colors } from 'helpers/variables';
import futuraBookEot from 'assets/fonts/futura-book.eot';
import futuraBookWoff2 from 'assets/fonts/futura-book.woff2';
import futuraBookWoff from 'assets/fonts/futura-book.woff';
import futuraBookTtf from 'assets/fonts/futura-book.ttf';

import ballantinesEFEot from 'assets/fonts/ballantinesEF.eot';
import ballantinesEFWoff2 from 'assets/fonts/ballantinesEF.woff2';
import ballantinesEFWoff from 'assets/fonts/ballantinesEF.woff';
import ballantinesEFTtf from 'assets/fonts/ballantinesEF.ttf';

const GlobalStyles = createGlobalStyle`

    @import '@guplabs/cookiecontrol/dist/cookiecontrol.min.css';

    @font-face {
        font-family: 'Futura LT W01 Book';
        src:url(${`${futuraBookEot}#iefix`});
        src:url(${`${futuraBookEot}#iefix`}) format("eot"),
        url(${`${futuraBookWoff2}`}) format("woff2"),
        url(${`${futuraBookWoff}`}) format("woff"),
        url(${`${futuraBookTtf}`}) format("truetype");
    }

    @font-face {
        font-family: 'Ballantines EF Script';
        src:url(${`${ballantinesEFEot}#iefix`});
        src:url(${`${ballantinesEFEot}#iefix`}) format("eot"),
        url(${`${ballantinesEFWoff2}`}) format("woff2"),
        url(${`${ballantinesEFWoff}`}) format("woff"),
        url(${`${ballantinesEFTtf}`}) format("truetype");
    }

    ${normalize()}

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    :root {
        /* Font-Sizes */
        --fs-base: ${rem(15)};

        --fs-h1: ${rem(25)};
        --fs-h2: ${rem(22)};
        --fs-h3: ${rem(20)};
        --fs-h4: ${rem(18)};
        --fs-h5: ${rem(16)};
        --fs-h6: ${rem(15)};

        --fs-xxxxl: ${rem(40)};
        --fs-xxxl: ${rem(35)};
        --fs-xxl: ${rem(28)};
        --fs-xl: ${rem(24)};
        --fs-l: ${rem(18)};
        --fs-m: ${rem(16)};
        --fs-s: ${rem(14)};
        --fs-xs: ${rem(12)};

        /* Gaps */
        --gap-s: ${rem(2)};
        --gap-m: ${rem(6)};
        --gap-l: ${rem(10)};
        --gap-xl: ${rem(12)};
        --gap-xxl: ${rem(16)};
        --gap-xxxl: ${rem(24)};
        --gap-xxxxl: ${rem(30)};

        --consent-content-margin: 15px 15px 0 15px;

        @media(min-width: 466px) {
            --consent-content-margin: 15px;
        }

        @media(min-width: ${breakpoints.medium}) {

            /* Font-Sizes */
            --fs-base: ${rem(16)};

            --fs-h1: ${rem(40)};
            --fs-h2: ${rem(30)};
            --fs-h3: ${rem(22)};
            --fs-h4: ${rem(20)};
            --fs-h5: ${rem(18)};
            --fs-h6: ${rem(16)};

            --fs-xxxxl: ${rem(50)};
            --fs-xxxl: ${rem(40)};
            --fs-xxl: ${rem(32)};
            --fs-xl: ${rem(26)};
            --fs-l: ${rem(20)};
            --fs-m: ${rem(18)};
            --fs-s: ${rem(16)};
            --fs-xs: ${rem(14)};

            /* Gaps */
            --gap-s: ${rem(3)};
            --gap-m: ${rem(7)};
            --gap-l: ${rem(14)};
            --gap-xl: ${rem(20)};
            --gap-xxl: ${rem(24)};
            --gap-xxxl: ${rem(30)};
            --gap-xxxxl: ${rem(40)};
        }

        @media(min-width: ${breakpoints.large}) {

             /* Font-Sizes */
            --fs-base: ${rem(18)};

            /* Font-Sizes */
            --fs-h1: ${rem(65)};
            --fs-h2: ${rem(35)};
            --fs-h3: ${rem(25)};
            --fs-h4: ${rem(22)};
            --fs-h5: ${rem(20)};
            --fs-h6: ${rem(18)};

            --fs-xxxxl: ${rem(65)};
            --fs-xxxl: ${rem(50)};
            --fs-xxl: ${rem(40)};
            --fs-xl: ${rem(30)};
            --fs-l: ${rem(22)};
            --fs-m: ${rem(20)};
            --fs-s: ${rem(18)};
            --fs-xs: ${rem(14)};

            /* Gaps */
            --gap-s: ${rem(4)};
            --gap-m: ${rem(8)};
            --gap-l: ${rem(16)};
            --gap-xl: ${rem(24)};
            --gap-xxl: ${rem(32)};
            --gap-xxxl: ${rem(48)};
            --gap-xxxxl: ${rem(60)};
        }
    }

    html, body {
        height: 100%;
        -webkit-overflow-scrolling: auto;
        color: ${colors.textColor};
    }

    body {
        ${fontSizes.base}
        font-family: ${fontFamilies.default};
        line-height: 1.5;

        ${mq.mediumDown`
            &.off-canvas-active {
                overflow: hidden;
            }
        `};

        &.modal-active {
            overflow: hidden;
        }

    }

    .sb-show-main > div {
        height: 100%;
    }

    strong, b {
        font-weight: normal;
    }

    address {
        font-style: normal;
    }

    ul {
        margin-top: 0;
    }

    hr {
        border: 1px solid ${colors.gray300};
        border-bottom: none;
        margin: 1em 0;

        ${mq.medium`
            margin: 2em 0;
        `};
    }

    /* Animations-Styles für Modal */
    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 0.35s;
    }

    .ReactModal__Overlay--after-open{
        opacity: 1;
    }

    .ReactModal__Overlay--before-close{
        opacity: 0;
    }

    .icon {
      height: 1em;
    }

`;

export default GlobalStyles;
