/**
 * Scrollt zu Event Target oder DOM Knoten, je nachdem wie aufgerufen wird
 * @param {Object} elOrEvt Event oder DOM-Knoten
 * @example <div onClick={scrollTo} href="#foo" /> oder scrollTo(document.getElementById(...))
 */
const scrollTo = (elOrEvt, scrollOffset) => {
    let element;
    let offset = scrollOffset || 0;
    if (elOrEvt.currentTarget) {
        elOrEvt.preventDefault();
        elOrEvt.currentTarget.blur();
        const id = elOrEvt.currentTarget.getAttribute('href').substring(1);
        element = document.getElementById(`${id}`);
        offset = elOrEvt.currentTarget.getAttribute('data-scroll-offset');
    } else {
        element = elOrEvt;
    }

    if (element) {
        window.setTimeout(() => {
            window.scroll({
                top: element.getBoundingClientRect().top + window.pageYOffset - offset,
                behavior: 'smooth',
            });
        }, 0);
    }
};

export default scrollTo;
