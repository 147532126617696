import GoogleMapsLoader from 'google-maps';
import 'whatwg-fetch';

/**
 * Helper, um ein Bild aus einem GraphQL-Set per Dateiname zu bekommen
 * @param {Array} images Array aus der Query
 * @param {String} path Pfad des gewünschten Bildes
 * @returns {childImageSharp|undefined} Bild oder nix
 */
export const getImageFromNode = (images, path) =>
    images.edges.find(({ node }) => node.relativePath === path);

/**
 * Überprüft, welchen Typ ein Link hat
 * @param {string} to Der Link
 * @return {string} Typ des Links (external, internal oder anchor)
 */
export const getLinkType = to => {
    // Überprüft, ob Link mit / startet oder mit .pdf endet bzw. mit /flippingbook startet.
    // Diese Links müssen wie externe behandelt werden
    const internal =
        /^\/(?!\/)/.test(to) && !to.endsWith('.pdf') && !to.startsWith('/flippingbook/');
    const anchor = to && to.startsWith('#');
    let type = 'external';

    // Interner (Gatsby-Link)
    if (internal && !anchor) {
        type = 'internal';
    } else if (!internal && anchor) {
        type = 'anchor';
    }
    return type;
};

/**
 * Prüft den Status des "fetch" Response Objekts
 * @param  {Object} response Response Objekt von "fetch"
 * @return {Object}
 */
function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
}

const GOOGLE_API_KEY = 'AIzaSyDe2QJi4pFnl98TO_7LdPhTQR3rp0tdZLY';

/**
 * Lädt die Google Maps API
 * @returns {Promise}
 */
export const loadMapsApi = () => {
    GoogleMapsLoader.KEY = GOOGLE_API_KEY;
    GoogleMapsLoader.VERSION = 'weekly';
    GoogleMapsLoader.LANGUAGE = 'de';
    GoogleMapsLoader.REGION = 'DE';

    return new Promise(resolve => {
        GoogleMapsLoader.load(resolve);
    });
};

/**
 * Holt die Jobs von der Concludis-API
 * @returns {Promise}
 */
export const fetchConcludisJobs = () =>
    fetch('/jobs.json')
        .then(checkStatus)
        .then(res => res.json());

/**
 * Führt ein Geocoding bei einer Adresse aus
 * @param {String} address Adresse die geocodiert werden soll
 * @returns {Promise}
 */
export const geocode = address =>
    fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${GOOGLE_API_KEY}`
    )
        .then(checkStatus)
        .then(response => response.json());

/**
 * Erzeugt einen IntersectionObserver für ein bestimmtest Element
 * @param {HTMLElement} element Element, dass überwacht werden soll
 * @param {Function} callback Funktion die bei Änderungen aufgerufen wird
 * @param {Object} options Optionen des IntersectionObservers (https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/IntersectionObserver)
 * @returns {IntersectionObserver}
 */
export function observeIntersection(element, callback, options) {
    const observer = new IntersectionObserver(
        entries => entries.forEach(entry => callback(entry)),
        options
    );

    observer.observe(element);

    return observer;
}

/**
 * Führt eine Media Query aus und informiert optional einen Callback bei Änderungen.
 * @param  {String} query CSS Style Media Query: (min-width: 992px)
 * @param  {Function} callback (optional) Callback wird bei Änderungen informiert
 * @returns {Boolean|undefined} Wenn kein Callback übergeben wird, wird sofort das Ergebnis zurückgeliefert
 */
export function matchMedia(query, callback) {
    const res = window.matchMedia(query);

    if (!callback) {
        return res.matches;
    }

    callback(res.matches, res.media);

    return res.addListener(changed => {
        callback(changed.matches, changed.media);
    });
}

/**
 * Überprüft ein Gatsby-Link aktiv ist (auch auf Subebene) und setzt die benötigte Klasse
 * @param {bool} isPartiallyCurrent Flag, ob Pfad aktuell aktiv ist
 */
export const isPartiallyActive = ({ isPartiallyCurrent }) =>
    isPartiallyCurrent ? { className: 'navlink--is-active' } : null;

/**
 * Findet Object mit speziellem Wert innerhalb eines Array von Objects
 * @param {array} array Das zu durchsuchende Array
 * @param {string} key Key
 * @param {string/number} key Value, der verglichen wird
 */
export const getObjectByValue = (array, key, value, firstValueOnly) => {
    const result = array.filter(object => object[key] === value);
    return firstValueOnly ? result[0] : result;
};

/**
 * Loggt in die Browser-Konsole, wenn sie verfügbar ist
 * Logging wird in "production" nicht ausgeführt
 * @param  {...any} args Argumente die geloggt werden sollen
 */
export function log(...args) {
    if (
        typeof window !== 'undefined' &&
        window.console &&
        window.console.log &&
        typeof window.console.log === 'function' &&
        process.env.NODE_ENV === 'development'
    ) {
        window.console.log(...args);
    }
}
